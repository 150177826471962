import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import type { PaymentType, OrderPaymentType } from '../models/payment';
import Http from './../helpers/http';

type OrderState = {
  orderPaymentList: {
    loading: boolean;
    data: OrderPaymentType[];
    error?: string;
  };
  paymentList: {
    loading: boolean;
    data: PaymentType[];
    error?: string;
  };
};

const initialState: OrderState = {
  orderPaymentList: {
    loading: false,
    data: [],
  },
  paymentList: {
    loading: false,
    data: [],
  },
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    //Order payment List
    fetchOrderPaymenListRequest: (state) => {
      state.orderPaymentList.loading = true;
    },
    fetchOrderPaymenListSuccess: (state, action) => {
      state.orderPaymentList = {
        loading: false,
        data: action.payload,
      };
    },
    fetchOrderPaymenListFailure: (state, action) => {
      state.orderPaymentList.loading = false;
      state.orderPaymentList.error = action.payload;
    },
    //Add Order payment List
    fetchAddOrderPaymenSuccess: (state, action) => {
      state.orderPaymentList.data?.push(action.payload);
    },
    //Update Order payment List
    fetchUpdateOrderPaymenListSuccess: (state, action) => {
      state.orderPaymentList.data = state.orderPaymentList.data.map((payment) => {
        if (payment.id === action.payload.id) {
          return {
            ...payment,
            ...action.payload,
          };
        } else {
          return { ...payment };
        }
      });
    },
    //payment List
    fetchPaymenListRequest: (state) => {
      state.paymentList.loading = true;
    },
    fetchPaymenListSuccess: (state, action) => {
      state.paymentList = {
        loading: false,
        data: action.payload,
      };
    },
    fetchPaymenListFailure: (state, action) => {
      state.paymentList.loading = false;
      state.paymentList.error = action.payload;
    },
  },
});

export default paymentSlice.reducer;
export const paymentActions = paymentSlice.actions;

//ACTIONS
export const fetchOrderPaymenListAction = (orderId: number) => async (dispatch: any) => {
  dispatch(paymentActions.fetchOrderPaymenListRequest());
  try {
    const res = await Http.Get({
      path: `order/${orderId}/payment/`,
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed to get payment-list');
    }
    dispatch(paymentActions.fetchOrderPaymenListSuccess(result));
  } catch (error: any) {
    dispatch(paymentActions.fetchOrderPaymenListFailure(error.message));
  }
};
export const fetchPaymenListAction = () => async (dispatch: any) => {
  dispatch(paymentActions.fetchPaymenListRequest());
  try {
    const res = await Http.Get({
      path: `orders/payment/type`,
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed to get payment-list');
    }
    dispatch(paymentActions.fetchPaymenListSuccess(result));
  } catch (error: any) {
    dispatch(paymentActions.fetchPaymenListFailure(error.message));
  }
};

// Order list

export const AddOrderPaymenAction = (orderId: number, data: any) => async (dispatch: any) => {
  try {
    const res = await Http.Post({
      path: `order/${orderId}/payment`,
      data,
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed to get payment-list');
    }
    // console.log(result);
    dispatch(paymentActions.fetchAddOrderPaymenSuccess(result));
  } catch (error: any) {
    dispatch(paymentActions.fetchOrderPaymenListFailure(error.message));
  }
};
export const UpdateOrderPaymenAction = (orderId: number, paymenId: number, data: any) => async (dispatch: any) => {
  try {
    const res = await Http.Put({
      path: `order/${orderId}/payment/${paymenId}`,
      data,
      useAuth: true,
    });
    const result = await res.json();
    if (!res.ok) {
      throw new Error('Failed to get payment-list');
    }
    // console.log(result);
    dispatch(paymentActions.fetchUpdateOrderPaymenListSuccess(result));
  } catch (error: any) {
    dispatch(paymentActions.fetchOrderPaymenListFailure(error.message));
  }
};
