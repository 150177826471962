import { useState, Suspense, useEffect, useMemo } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { FaTachometerAlt, FaImages } from 'react-icons/fa';
import { BsUiChecksGrid } from 'react-icons/bs';
import { IoIosArrowBack } from 'react-icons/io';
import { SiXcode } from 'react-icons/si';
import {
  AiOutlineShop,
  AiOutlineMenu,
  AiOutlineFileSearch,
} from 'react-icons/ai';
import {
  RiShoppingBag3Line,
  RiHome8Line,
  RiPagesLine,
  RiAdminLine,
  RiQuestionnaireLine,
} from 'react-icons/ri';
import { MdManageAccounts } from 'react-icons/md';
import { CgMenuMotion } from 'react-icons/cg';
import { RiSecurePaymentFill } from 'react-icons/ri';
import { MdPeopleAlt } from 'react-icons/md';
import {
  MdOutlineLocalShipping,
  MdSettings,
  MdOutlineCampaign,
} from 'react-icons/md';
import { HiOutlineUsers } from 'react-icons/hi';
import NavMenu from './NavMenu';
import clsx from 'clsx';
import { Dropdown } from 'antd';
import { useAppDispatch, useAppSelector } from './../hooks/redux';
import { authMeAction, logoutAction } from './../store/authSlice';

import { logoImg, logoIcon } from './../constants/images';
import Loader from './Loader';
import ErrorBoundary from './ErrorBoundary';
import getJson from '../helpers/getJson';
import { fetchAccrssControlAction } from '../store/settingSlice';

type Props = {};

const Layout = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const [collapsedSidebar, setCollapsedSidebar] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const me = useAppSelector((state) => state.auth.me);
  const accrssControl = useAppSelector((state) => state.setting.accrssControl);

  useEffect(() => {
    dispatch(fetchAccrssControlAction());
    dispatch(authMeAction()).finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loading && !me.data) {
      navigation('/login');
    }
    // eslint-disable-next-line
  }, [loading, me.data]);

  const onLogout = () => {
    dispatch(logoutAction());
  };
  function profileMenu() {
    return (
      <div className='bg-white rounded flex flex-col gap-1 drop-shadow overflow-hidden'>
        {me.data?.permissions[0].permissions.includes('admin_manage') ? (
          <Link
            to={`/admin-manage/list/${me.data?.id}`}
            className='px-2 py-1 text-center hover:text-secondary bg-primary bg-opacity-0 hover:bg-opacity-20 w-36'>
            Profile
          </Link>
        ) : null}
        <button
          className='px-2 py-1 bg-primary bg-opacity-0 hover:bg-opacity-20'
          onClick={onLogout}>
          Logout
        </button>
      </div>
    );
  }

  const permissions = useMemo(() => {
    if (me.data && me.data.permissions.length) {
      return me.data.permissions[0].permissions;
    } else {
      return [];
    }
  }, [me.data]);

  const orderManagerType = useMemo(() => {
    let name: string = '';
    if (me.data && me.data.permissions.length) {
      const manage =
        me.data.permissions[0].permissions.includes('order_manage');
      const web =
        me.data.permissions[0].permissions.includes('order_manage_web');
      const coverwala =
        me.data.permissions[0].permissions.includes('order_coverwala');
      const manual = me.data.permissions[0].permissions.includes(
        'order_manage_manual'
      );
      if (manage) {
        name = 'order_manage';
      }
      if (web) {
        name = 'order_manage_web';
      }
      if (manual) {
        name = 'order_manage_manual';
      }
      if (coverwala) {
        name = 'order_coverwala';
      }
      return name;
    } else {
      return '';
    }
  }, [me.data]);
  // console.log(orderManagerType);
  if (loading) {
    return <Loader />;
  }
  if (
    getJson<{ site_status?: '0' | '1' }>(accrssControl.data?.value ?? '')
      ?.site_status === '1'
  ) {
    console.error('site is under maintenance');
    return null;
  }
  return (
    <div className='h-screen flex'>
      <ProSidebar
        breakPoint='md'
        collapsed={collapsedSidebar}
        toggled={toggleSidebar}
        onToggle={(toggle) => setToggleSidebar(toggle)}>
        <SidebarHeader className='py-2 center-inner'>
          <Link to={'/'} className='w-[40%]'>
            <img
              src={collapsedSidebar ? logoIcon : logoImg}
              alt='applegadgets'
              className='w-full'
            />
          </Link>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape='circle'>
            {permissions.includes('dashboard') && (
              <SubMenu title={'Dashboard Manage'} icon={<FaTachometerAlt />}>
                <NavMenu to='/'>Dashboard</NavMenu>
                <NavMenu to='/price_contributor'>Price Contributor</NavMenu>
                <NavMenu to='/stockout_product_list'>Stockout List</NavMenu>
                <NavMenu to='/active_list'>Active Log</NavMenu>
              </SubMenu>
            )}

            {permissions.includes('media') && (
              <NavMenu to='/media' icon={<FaImages />}>
                Media
              </NavMenu>
            )}

            {/* order manage */}
            {permissions.includes(orderManagerType) && (
              <SubMenu title={'Order Manage'} icon={<BsUiChecksGrid />}>
                <NavMenu to='/order/new'>Add New Order</NavMenu>
                <NavMenu to='/order'>Order List</NavMenu>
                <NavMenu to='/order/pre'>Pre Orders</NavMenu>
                <NavMenu to='/order/pending_product'>
                  Pending Product List
                </NavMenu>
                <NavMenu to='/order/sales_staff'>Sales Staff List</NavMenu>
              </SubMenu>
            )}
            {/* /order manage */}
            {/* bill manage */}
            {permissions.includes('bill_manage') && (
              <SubMenu title={'Bill Manage'} icon={<RiSecurePaymentFill />}>
                <NavMenu to='/bill'>Bill List</NavMenu>
              </SubMenu>
            )}
            {/* /bill manage */}
            {/* accounts manage */}
            {permissions.includes('accounts_manage') && (
              <SubMenu title={'Payment Manage'} icon={<MdManageAccounts />}>
                <NavMenu to='/account'>Order Payment List</NavMenu>
              </SubMenu>
            )}
            {/* /accounts manage */}

            {/* product manage */}
            {permissions.includes('product_manage') && (
              <SubMenu title={'Product Manage'} icon={<AiOutlineShop />}>
                <NavMenu to='/product/new'>Add New</NavMenu>
                <NavMenu to='/product'>All Product</NavMenu>
                <NavMenu to='/attributes'>Attributes</NavMenu>
                <NavMenu to='/specifications'>Specifications</NavMenu>
              </SubMenu>
            )}
            {/* /product manage */}

            {/* category manage */}
            {permissions.includes('category_manage') && (
              <SubMenu title={'Category Manage'} icon={<BsUiChecksGrid />}>
                <NavMenu to='/category/new'>Add New</NavMenu>
                <NavMenu to='/category'>All Categories</NavMenu>
              </SubMenu>
            )}
            {/* category manage */}

            {/* brand manage */}
            {permissions.includes('brand_manage') && (
              <SubMenu title={'Brand Manage'} icon={<RiShoppingBag3Line />}>
                <NavMenu to='/brand/new'>Add New</NavMenu>
                <NavMenu to='/brand'>All Brands</NavMenu>
              </SubMenu>
            )}
            {/* /brand manage */}

            {/* customer manage */}
            {permissions.includes('customer') && (
              <SubMenu title={'Customer Manage'} icon={<HiOutlineUsers />}>
                <NavMenu to='/customer'>Customer List</NavMenu>
                <NavMenu to='/reward/customer'>Customer Points</NavMenu>
                <NavMenu to='/reward/setting'>Reward Point Setting</NavMenu>
              </SubMenu>
            )}
            {/* /customer manage */}

            {permissions.includes('search_terms') && (
              <NavMenu to='/terms' icon={<AiOutlineFileSearch />}>
                Search Terms
              </NavMenu>
            )}

            {/* menu manage */}
            {permissions.includes('menu_manage') && (
              <NavMenu to='/menu' icon={<CgMenuMotion />}>
                Menu Manage
              </NavMenu>
            )}
            {/* /menu manage */}

            {/* custom page manage */}
            {permissions.includes('custom_page') && (
              <NavMenu to='/custom-page' icon={<RiPagesLine />}>
                Custom Page
              </NavMenu>
            )}
            {/* /custom page manage */}
            {/* custom page manage */}
            {permissions.includes('faq_manage') && (
              <NavMenu to='/faq' icon={<RiQuestionnaireLine />}>
                FAQ Manage
              </NavMenu>
            )}
            {/* /custom page manage */}

            {/* delivery manage */}
            {permissions.includes('delivery_manage') && (
              <SubMenu
                title={'Delivery Manage'}
                icon={<MdOutlineLocalShipping />}>
                <NavMenu to='/delivery/type'>Delivery Type</NavMenu>
                {/* <NavMenu to='/delivery/option'>
                                Delivery Option
                            </NavMenu> */}
                <NavMenu to='/delivery/city-area'>City / Area</NavMenu>
                <NavMenu to='/delivery/city-under-delivery'>
                  City Under Delivery
                </NavMenu>
              </SubMenu>
            )}
            {/* /delivery manage */}

            {/* app content manage */}
            {permissions.includes('app_manage') && (
              <SubMenu title={'App Manage'} icon={<SiXcode />}>
                <NavMenu to='/app-content/menu'>Menu Manage</NavMenu>
                <NavMenu to='/app-content/layout'>Layout Manage</NavMenu>
                <NavMenu to='/app-content/slider'>Slider Manage</NavMenu>
                <NavMenu to='/app-content/clip'>Clip Manage</NavMenu>
                <NavMenu to='/app-content/notification'>
                  Notification Manage
                </NavMenu>
              </SubMenu>
            )}
            {/* /app content manage */}

            {/* home content manage */}
            {permissions.includes('home_content_manage') && (
              <SubMenu title={'Content Manage'} icon={<RiHome8Line />}>
                <NavMenu to='/home-content/layout'>Layout Manage</NavMenu>
                <NavMenu to='/home-content/slider'>Slider Manage</NavMenu>
                <NavMenu to='/home-content/seo'>Home SEO</NavMenu>
                <NavMenu to='/content/banner'>Banner Manage</NavMenu>
              </SubMenu>
            )}
            {/* /home content manage */}

            {/* campaign page */}
            {permissions.includes('campaign') && (
              <SubMenu title={'Marketing '} icon={<MdOutlineCampaign />}>
                <NavMenu to='/campaign/coupon'>Coupon Code</NavMenu>
                <NavMenu to='/campaign/offer'>Offer</NavMenu>
                <NavMenu to='/campaign/point'>Point</NavMenu>
                <NavMenu to='/campaign/chat'>Chat</NavMenu>
                <NavMenu to='/campaign/popup'>Device Popup</NavMenu>
                <NavMenu to='/campaign/press_coverage'>Press Coverage</NavMenu>
              </SubMenu>
            )}
            {/* /campaign page */}

            {/* career page */}
            {permissions.includes('career') && (
              <SubMenu title={'Career Page'} icon={<MdPeopleAlt />}>
                <NavMenu to='/career'>Main Page</NavMenu>
                <NavMenu to='/career/new'>Add New Job</NavMenu>
                <NavMenu to='/career/list'>All Jobs</NavMenu>
                <NavMenu to='/career/all-applicant'>All Applicants</NavMenu>
              </SubMenu>
            )}
            {/* /career page */}

            {/* admin manage page */}
            {permissions.includes('admin_manage') && (
              <SubMenu title={'Admin Manage'} icon={<RiAdminLine />}>
                <NavMenu to='/admin-manage/new'>Add New</NavMenu>
                <NavMenu to='/admin-manage/list'>Admin List</NavMenu>
              </SubMenu>
            )}
            {/* /admin manage page */}

            {/* setting page */}
            {permissions.includes('setting') && (
              <SubMenu title={'Setting'} icon={<MdSettings />}>
                <NavMenu to='/setting'>Site Setting</NavMenu>
                <NavMenu to='/setting/redirection'>Redirection</NavMenu>
                <NavMenu to='/setting/shop-address'>Shop Address</NavMenu>
                <NavMenu to='/setting/emi'>EMI</NavMenu>
                <NavMenu to='/setting/footer'>Footer</NavMenu>
              </SubMenu>
            )}
            {/* /setting page */}
          </Menu>
        </SidebarContent>

        <SidebarFooter className='center-inner py-2'>
          <button
            className={clsx(
              'h-10 w-10 text-xl center-inner hover:text-primary hover:text-2xl duration-300',
              collapsedSidebar && 'rotate-180'
            )}
            onClick={() => setCollapsedSidebar(!collapsedSidebar)}>
            <IoIosArrowBack />
          </button>
        </SidebarFooter>
      </ProSidebar>
      <main className='h-full w-full overflow-auto bg-card-4'>
        <div className='w-full py-2 bg-secondary flex items-center justify-between px-4 sticky top-0 z-20'>
          <div>
            <button
              type='button'
              className='text-white text-2xl md:hidden'
              onClick={() => setToggleSidebar(true)}>
              <AiOutlineMenu />
            </button>
          </div>
          <Dropdown overlay={profileMenu} placement='bottomRight' arrow>
            {/* <Avatar src="https://joeschmoe.io/api/v1/random" className='border border-white' size={'large'}>{me.data?.firstname}</Avatar> */}
            <p className='cursor-pointer rounded border border-primary text-white px-1 py-1'>
              {me.data?.firstname}
            </p>
          </Dropdown>
        </div>
        <Suspense fallback={<Loader />}>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Suspense>
      </main>
    </div>
  );
};

export default Layout;
